import IndexMain from "./main-page"
import Head from "next/head"
const Index = () => {
  return (
    <div>
      <IndexMain />
    </div>
  )
}

export default Index
